<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">PO - Purchase Order</h1>
      </div>
      <b-tabs class="mt-3">
        <b-tab title="Detail">
          <div class="bg-white">
            <div class="title-tabs">Detail</div>
            <div class="p-3">
              <b-row>
                <b-col cols="12" md="6">
                  <InputText
                    placeholder="เลขที่รับสั่งซื้อ"
                    textFloat="เลขที่รับสั่งซื้อ"
                    disabled
                    v-model="form.po_no"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputSelect
                    placeholder="ผู้ผลิต"
                    :disabled="form.status_id > 1"
                    title="ผู้ผลิต"
                    v-model="form.brand_id"
                    valueField="id"
                    textField="name"
                    :options="listmanufacturer"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- เลือกผู้ผลิต --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                </b-col>
                <b-col cols="12" md="6">
                  <InputSelect
                    v-model="form.destination_branch_id"
                    placeholder="ผลิตเพื่อสาขา"
                    :disabled="form.status_id > 1"
                    title="ผลิตเพื่อสาขา"
                    valueField="id"
                    textField="name"
                    :options="branchList"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- เลือกสาขา --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                </b-col>
                <b-col cols="12" md="6">
                  <InputText
                    v-model="form.created_by"
                    placeholder="ผู้ออกเอกสาร"
                    textFloat="ผู้ออกเอกสาร"
                    disabled
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputDatePickerFilter
                    :v="$v.form.order_date"
                    :isValidate="$v.form.order_date.$error"
                    textFloat="วันที่ออก (ดด/มม/ปปปป)"
                    :disabled="form.status_id > 1"
                    name="valid-form"
                    ref="valid-form"
                    format="dd/MM/yyyy "
                    v-model="form.order_date"
                    type="date"
                    :isRequired="true"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputDatePickerFilter
                    v-model="form.due_date"
                    textFloat="กำหนดส่งมอบ (ดด/มม/ปปปป)"
                    :disabled="form.status_id > 1"
                    :isRequired="true"
                    name="valid-form"
                    ref="valid-form"
                    format="dd/MM/yyyy "
                    type="date"
                    :v="$v.form.due_date"
                    :isValidate="$v.form.due_date.$error"
                  />
                </b-col>
                <b-col cols="12">
                  <div>
                    <div class="title-tabs my-3">Product List</div>
                    <!-- Select Product -->

                    <b-row class="mb-2">
                      <b-col cols="2" sm="6" md="6" class="mt-2">
                        <b-button
                          v-if="form.status_id < 2"
                          @click.prevent="selectProduct"
                          class="btn-filter mr-2"
                        >
                          <span class="d-none d-sm-flex align-items-center">
                            <span class="mr-2">Select Items </span>
                            <font-awesome-icon
                              icon="chevron-right"
                              class="pointer"
                            />
                          </span>
                          <font-awesome-icon
                            icon="chevron-right"
                            class="d-sm-none"
                          />
                        </b-button>
                        <span class="text-black"
                          >Selected {{ this.allItem.length }} list</span
                        >
                      </b-col>
                      <b-col cols="10" sm="6" md="6" class="mt-2">
                        <b-input-group class="mb-2">
                          <b-form-input
                            type="text"
                            id="header-search-bar"
                            class="search-bar"
                            @keyup.enter="handleSearch"
                            placeholder="Search Product"
                            v-model="filter.search"
                          >
                          </b-form-input>
                          <b-input-group-append is-text>
                            <b-iconstack
                              font-scale="2"
                              type="submit"
                              @click="handleSearch"
                            >
                              <b-icon
                                stacked
                                icon="search"
                                scale="0.5"
                                variant="grey"
                              ></b-icon>
                            </b-iconstack>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      striped
                      hover
                      :fields="fields"
                      :items="items"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center text-black my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="ml-2">Loading...</strong>
                        </div>
                      </template>
                      <template v-slot:cell(product_image)="{ item }">
                        <div class="position-relative picture-text pic-table">
                          <div
                            v-if="item.product_image"
                            class="pic-box"
                            v-bind:style="{
                              'background-image':
                                'url(' + item.product_image + ')',
                            }"
                          ></div>
                          <div v-else>
                            <img
                              class="pic-box pt-0"
                              :src="default_image"
                              alt="default Image"
                              srcset=""
                            />
                          </div>
                        </div>
                      </template>
                      <template v-slot:cell(price)="{ item }">
                        {{ item.price | numeral("0,0.00") }}
                      </template>
                      <template v-slot:cell(total_price)="{ item }">
                        {{ item.total_price | numeral("0,0.00") }}
                      </template>
                      <template
                        v-slot:cell(manufacturer_product_code)="{ item, index }"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center text-center"
                        >
                          <InputText
                            placeholder="รหัสสินค้าผู้ผลิต"
                            class="mr-1 mb-0 input-quantity"
                            disabled
                            v-model="item.manufacturer_product_code"
                          ></InputText>
                          <font-awesome-icon
                            v-if="form.status_id < 2"
                            class="check-circle pointer text-warning ml-1"
                            icon="pencil-alt"
                            @click="openModalManufacturer(item)"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(quantity)="{ item, index }">
                        <div
                          class="d-flex justify-content-center align-items-center text-center"
                        >
                          <font-awesome-icon
                            v-if="form.status_id < 2"
                            class="check-circle pointer mr-1"
                            icon="plus-circle"
                            @click="handleProductQuantity(1, item)"
                          />
                          <InputText
                            placeholder="จำนวน"
                            type="number"
                            :disabled="form.status_id > 1"
                            @onKeyup.enter="
                              handleProductQuantityInput($event, item)
                            "
                            class="mx-1 input-quantity mb-0"
                            v-model="item.quantity"
                          ></InputText>

                          <font-awesome-icon
                            v-if="form.status_id < 2"
                            class="check-circle pointer ml-1"
                            icon="minus-circle"
                            @click="handleProductQuantity(0, item)"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(action)="{ item }">
                        <div v-if="form.status_id < 2">
                          <b-button
                            variant="icon"
                            class="px-0"
                            @click="deleteProduct(item.product_id)"
                          >
                            <b-icon icon="trash-fill"></b-icon>
                          </b-button>
                        </div>
                        <div v-else>
                          <span
                            @click="openModalCheckInvoice(item)"
                            class="text-link"
                            >ตรวจรับ</span
                          >
                        </div>
                      </template>
                    </b-table>
                  </div>
                </b-col>
                <b-col cols="12">
                  <Pagination
                    @handleChangeTake="handleChangeTake"
                    :pageOptions="pageOptions"
                    :filter="filter"
                    :rows="rows"
                    @pagination="pagination"
                  />
                </b-col>
                <b-col cols="12">
                  <InputTextArea
                    textFloat="หมายเหตุ"
                    type="text"
                    class="mt-0 custom-input"
                    placeholder="หมายเหตุ"
                    rows="3"
                    name="message"
                    v-model="form.remark"
                /></b-col>
              </b-row>
            </div>
          </div>

          <FooterAction
            v-if="id == 0"
            saveText="สร้าง PO"
            @submit="checkForm(0)"
            routePath="/setting/inventory/purchase-order"
          />
          <FooterAction
            v-if="form.status_id == 1"
            saveText="อนุมัติ"
            @customAction="checkForm(0)"
            customText="บันทึก"
            cancelText="ไม่อนุมัติ"
            backAction
            @routePath="checkForm(3)"
            @submit="checkForm(1)"
            routePath="/setting/inventory/purchase-order"
          />

          <FooterAction
            v-else-if="form.status_id == 2"
            saveText="โอนเข้าคลัง"
            customText="ปิด PO"
            @customAction="checkForm(4)"
            @submit="checkForm(2)"
            routePath="/setting/inventory/purchase-order"
          />
        </b-tab>
        <b-tab title="History"> </b-tab>
      </b-tabs>
    </div>
    <ModalSelectProduct
      :isApprove="isApprove"
      ref="modalSelectProduct"
      :selectedItem="form.products"
      @success="handleSelectProduct"
    ></ModalSelectProduct>

    <ModalManufacturer
      ref="modalManufacturer"
      @updateProduct="updateProduct"
      :form="form.products"
    ></ModalManufacturer>

    <ModalCheckInvoice
      ref="modalCheckInvoice"
      @updateReceiveProduct="updateReceiveProduct"
      :form="form.products"
    ></ModalCheckInvoice>
  </div>
</template>

<script>
import ModalSelectProduct from "./components/ModalSelectProduct.vue";
import ModalManufacturer from "./components/ModalManufacturer.vue";
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading.vue";
import ModalCheckInvoice from "./components/ModalCheckInvoice.vue";
export default {
  components: {
    ModalSelectProduct,
    OtherLoading,
    ModalManufacturer,
    ModalCheckInvoice,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      title: "",
      id: this.$route.params.id,
      form: {
        po_id: null,
        brand_id: null,
        manufacturer_id: null,
        destination_branch_id: null,
        order_date: "",
        due_date: "",
        remark: "",
        products: [],
        status_id: 0,
      },
      fields: [
        { label: "รูปภาพ", key: "product_image", thStyle: { width: "10%" } },

        { label: "รหัสสินค้า", key: "barcode", thStyle: { width: "20%" } },
        {
          label: "รหัสสินค้าผู้ผลิต",
          key: "manufacturer_product_code",
          thStyle: { width: "10%" },
        },
        {
          label: "จำนวน",
          key: "quantity",
          thStyle: { width: "10%" },
        },
        { label: "ราคา", key: "price", thStyle: { width: "10%" } },
        { label: "มูลค่ารวม", key: "total_price", thStyle: { width: "10%" } },
        {
          label: "รับแล้ว",
          key: "received_quantity",
          thStyle: { width: "10%" },
        },
        {
          label: "โอนแล้ว",
          key: "transfered_quantity",
          thStyle: { width: "10%" },
        },

        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      allItem: [],
      filteredItems: [],
      searchDisplayProduct: "",
      isEdit: false,
      listmanufacturer: [],
      branchList: [],
      isApprove: false,
      form_receive: [],
    };
  },
  async created() {
    await this.getListmanufacturer();
    await this.getData();
    await this.getBranchList();
  },
  validations: {
    form: {
      due_date: { required },
      order_date: { required },
    },
  },
  methods: {
    async getData() {
      if (this.id < 1) {
        return;
      }
      this.isLoading = true;
      this.isBusy = true;

      const res = await this.axios(`/po/${this.id}`);

      if (res.data.result == 1) {
        this.form = res.data.detail;
        if (this.form.status_id == 1) {
          this.isApprove = true;
        }
        this.items = res.data.detail.products;

        this.form.products = [...this.items];
        this.allItem = this.items;
        this.filteredItems = [...this.allItem];
        this.rows = this.allItem.length;
        this.updateDisplayedItems();

        this.isLoading = false;
      }

      this.isBusy = false;
    },
    async getListmanufacturer() {
      const res = await this.axios(`/Brand/GetListBrandOption`);
      this.listmanufacturer = res.data.detail;
    },
    async getBranchList() {
      const res = await this.axios(`/branch/listAll`);
      this.branchList = res.data.detail;
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(true);
    },
    handleSelectProduct(items) {
      if (items) {
        this.allItem = items;
      }

      this.isBusy = true;
      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = items.length;
      this.form.products = items;
      this.filteredItems = items;
      this.isBusy = false;
    },

    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
    async deleteProduct(deleteId) {
      this.allItem = this.allItem.filter((x) => x.product_id !== deleteId);
      this.filteredItems = this.filteredItems.filter(
        (x) => x.product_id !== deleteId
      );
      this.form.products = this.form.products.filter(
        (x) => x.product_id !== deleteId
      );
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    handleProductQuantity(isPlus, item) {
      if (isPlus) {
        item.quantity += 1;
        item.total_price += item.price;
      } else if (item.quantity > 1) {
        item.quantity -= 1;
        item.total_price -= item.price;
      } else {
        this.allItem = this.allItem.filter(
          (x) => x.product_id !== item.product_id
        );
        this.filteredItems = this.filteredItems.filter(
          (x) => x.product_id !== item.product_id
        );
        this.form.products = this.form.products.filter(
          (x) => x.product_id !== item.product_id
        );
        this.updateDisplayedItems();
      }
    },
    handleProductQuantityInput(event, item) {
      if (!item.quantity) {
        this.allItem = this.allItem.filter(
          (x) => x.product_id !== item.product_id
        );
        this.filteredItems = this.filteredItems.filter(
          (x) => x.product_id !== item.product_id
        );
        this.form.products = this.form.products.filter(
          (x) => x.product_id !== item.product_id
        );
        this.updateDisplayedItems();
        return;
      }
      item.total_price = item.quantity * item.price;
    },
    async checkForm(type) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");

      if (!type) {
        let payload = { po_id: this.id, ...this.form, is_approve: null };
        const res = await this.axios.post(`/po/save`, payload);
        if (res.data.result === 1) {
          this.successAlert();
          this.$router.push(`/setting/inventory/purchase-order`);
        } else {
          this.errorAlert(res.data.message);
        }
      } else if (type == 1 || type == 3) {
        let payload = {
          po_id: this.id,
          ...this.form,
          is_approve: type == 1 ? true : false,
        };
        const res = await this.axios.post(`/po/save`, payload);
        if (res.data.result === 1) {
          this.successAlert();
          this.$router.push(`/setting/inventory/purchase-order`);
        } else {
          this.errorAlert(res.data.message);
        }
      } else if (type === 2) {
        // let payload = { po_id: this.id, ...this.form, is_approve: false };
        // const res = await this.axios.post(`/po/save`, payload);
        // if (res.data.result === 1) {
        //   this.successAlert();
        //   this.$router.push(`/setting/inventory/purchase-order`);
        // } else {
        //   this.errorAlert(res.data.message);
        // }
      }
      this.$bus.$emit("hideLoading");
    },
    openModalManufacturer(item) {
      this.$refs.modalManufacturer.showModal(item);
    },
    updateProduct(item) {
      let index = this.form.products.findIndex(
        (x) => x.product_id == item.product_id && item.id == x.id
      );

      this.form.products[index].manufacturer_product_code =
        item.manufacturer_product_code;
      this.form.products[index].remark = item.remark;
    },
    openModalCheckInvoice(item) {
      this.$refs.modalCheckInvoice.showModal(item);
    },
    async updateReceiveProduct() {
      await this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.check-circle {
  width: 15px;
  height: 15px;
}
.input-quantity ::v-deep input {
  text-align: center !important;
}
</style>
